import React from 'react'
// import Img from 'gatsby-image'
// import CategoriesSidebar from "../components/news/categories-sidebar";
import Markdown from "react-markdown";
// import Flickity from "react-flickity-component";
import Share from "../components/common/share";
import LayoutSecondary from "../components/common/layout-secondary";
import {replaceUploadsDir} from "../utils/gatsby-node-helpers";
import SEO from "../components/common/seo";
import {graphql} from 'gatsby'
import rehypeRaw from "rehype-raw";

const PostTemplate = (props) => {
    const lang = props.pageContext.locale.path.default;
    const schema =
        {
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "headline": props.data.strapiPost.title,
            "image": "https://www.tecalis.com" + props.data.strapiPost.media.localFile.publicURL,
            "author": {
                "@type": "Organization",
                "name": "Tecalis"
            },
            "publisher": {
                "@type": "Organization",
                "name": "Tecalis",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.tecalis.com" + require("../images/logo-tecalis-square.jpg").default
                }
            },
            "datePublished": props.data.strapiPost.date_iso,
            "dateModified": props.data.strapiPost.date_iso,
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://www.tecalis.com/"
            },
        };

    return (
        <LayoutSecondary pageContext={props.pageContext} cookies={props.data.cookies}>
            <SEO lang={lang}
                 title={props.data.strapiPost.title + " | Noticia - Tecalis"}
                 description={props.data.strapiPost.resume}
                 schemaMarkup={schema} image={props.data.strapiPost.media.localFile.publicURL}/>
            <section className="bg-white pt-8 pb-9 pt-md-11 pb-md-13">
                <div className="container">

                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            {/** Heading */}
                            <h1 className="display-4 font-weight-bold spacing title-feed">
                                Noticia
                                <br/><br/>
                            </h1>
                        </div>
                    </div>

                    <div className="row">
                        {/** Post */}
                        <div className="col-md-8 offset-md-2">
                            {/** Cabecera post */}

                            {/** Heading Cabecera*/}
                            <h1 className="display-4 text-left spacing font-weight-bold">
                                {props.data.strapiPost.title}
                            </h1>

                            {/** Subheading Cabecera */}
                            <p className="lead mb-7 spacing text-muted">
                                {props.data.strapiPost.description}
                            </p>

                            {/** Meta */}
                            <div className="row align-items-center py-5 border-top border-bottom">
                                <div className="col-auto">

                                    {/** Avatar */}
                                    <div className="avatar avatar-lg">
                                        <img src={require("../../static/favicon.ico").default} alt="Author" title="Author"
                                             className="avatar-img rounded-circle"/>
                                    </div>

                                </div>
                                <div className="col ml-n5">

                                    {/** Name */}
                                    <h6 className="text-uppercase mb-0 spacing">
                                        Tecalis
                                    </h6>

                                    {/** Date */}
                                    <time className="font-size-sm text-muted spacing"
                                          dateTime="2020-01-01">
                                        Publicado en {props.data.strapiPost.publish_date}
                                    </time>

                                </div>
                                <div className="col-auto">

                                    {/** Share */}
                                    <span
                                        className="h6 text-uppercase spacing text-muted d-none d-md-inline mr-4">
                                              Compartir:
                                            </span>

                                    {/** Icons */}
                                    <Share
                                        socialConfig={{
                                            twitterHandle: props.data.site.siteMetadata.twitterHandle,
                                            config: {
                                                url: props.location.href,
                                                title: props.data.strapiPost.title,
                                            },
                                        }}
                                    />

                                </div>
                            </div>


                            {/** Cuerpo post */}

                            <div className="cuerpo-post check-list">

                                <Markdown children={replaceUploadsDir(props.data.strapiPost.content)} rehypePlugins={[rehypeRaw]} components={{
                                    img: ({node, ...props}) => {
                                        // console.log(node);
                                        // console.log(props);
                                        return <img src={props.src} {...props} alt={props.alt} />
                                    }
                                }}/>

                                <br/>

                                {/** Slider */}
                                {/*<Flickity*/}
                                {/*    className={''} // default ''*/}
                                {/*    elementType={'div'}*/}
                                {/*    options={{*/}
                                {/*        "wrapAround": true,*/}
                                {/*        "prevNextButtons": false,*/}
                                {/*        "pageDots": true,*/}
                                {/*        "imagesLoaded": true,*/}
                                {/*        "adaptiveHeight": false*/}
                                {/*    }} // takes flickity options {}*/}
                                {/*    disableImagesLoaded={false} // default false*/}
                                {/*    reloadOnUpdate // default false*/}
                                {/*    static // default false*/}
                                {/*>*/}

                                {/*    /!*{props.data.strapiPost.media.map((image, i) => (*!/*/}
                                {/*    /!*    <div className="w-100" key={1}>*!/*/}
                                {/*    /!*        <img src={props.data.strapiPost.media.childImageSharp.fluid.base64}  alt={props.data.strapiPost.media.name}*!/*/}
                                {/*    /!*             className="img-fluid rounded" />*!/*/}
                                {/*    /!*    </div>*!/*/}
                                {/*    /!*))}*!/*/}

                                {/*</Flickity>*/}

                            </div>
                            {/** ./ Fin Cuerpo post */}
                        </div>
                    </div>
                    {/**  /. Post */}

                    {/** Sidebar */}
                    {/*<CategoriesSidebar/>*/}
                    {/** /. Sidebar */}

                </div>
                {/** /. row */}
                {/** /. container */
                }
            </section>
        </LayoutSecondary>
    )
}

export default PostTemplate

export const postPageQuery = graphql`
query PostTemplate($id: String!, $lang: String!, $dateFormat: String!) {
    strapiPost(id: {eq: $id}) {
        title
        description
        content
        resume
        media{
            name
            localFile {
                publicURL
            }
        }
        publish_date(formatString: $dateFormat, locale: $lang)
        date_iso: publish_date(formatString: "YYYY-MM-DDThh:mm:ssTZD")
    }
    cookies: markdownRemark(
        frontmatter: {
            lang: {
                eq: $lang
            }
            name: {
                eq: "cookies"
            }
        }) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
    site {
        siteMetadata {
            title
            twitterHandle
        }
    }
}`
